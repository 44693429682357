export enum TABS {
  MAIN = 'main',
  SETTINGS = 'settings',
  SUBMIT_MESSAGE = 'submitMessage',
  AUTOMATIONS = 'automations',
  SUPPORT = 'support',
  UPGRADE = 'upgrade',
  PAYMENT = 'payment',
  EMAIL_MARKETING = 'emailMarketing',
  CONTACT_LIST_V2 = 'contactListV2',
}

export enum MAIN_APPLICATION_TYPES {
  AUTOMATIONS = 'automations',
  WORKFLOWS = 'workflows',
  PRICE_QUOTES = 'priceQuotes',
  GET_SUBSCRIBERS = 'getSubscribers',
  PAYMENT = 'payment',
}

export const PRESET_NAME_TO_APPLICATION_NAME = {
  contact: MAIN_APPLICATION_TYPES.AUTOMATIONS,
  enterContest: MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'contest-entry': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  feedback: MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'customer-satisfaction': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  jobApplication: MAIN_APPLICATION_TYPES.WORKFLOWS,
  'job-application': MAIN_APPLICATION_TYPES.WORKFLOWS,
  getAQuote: MAIN_APPLICATION_TYPES.PRICE_QUOTES,
  'price-quote': MAIN_APPLICATION_TYPES.PRICE_QUOTES,
  quiz: MAIN_APPLICATION_TYPES.AUTOMATIONS,
  salesLead: MAIN_APPLICATION_TYPES.WORKFLOWS,
  'sales-lead': MAIN_APPLICATION_TYPES.WORKFLOWS,
  support: MAIN_APPLICATION_TYPES.WORKFLOWS,
  testimonial: MAIN_APPLICATION_TYPES.AUTOMATIONS,
  subscribers: MAIN_APPLICATION_TYPES.GET_SUBSCRIBERS,
  donation: MAIN_APPLICATION_TYPES.PAYMENT,
  payment: MAIN_APPLICATION_TYPES.PAYMENT,
}

export enum MAIN_FOOTER_TYPES {
  FORM_BUILDER = 'FORM_BUILDER',
  PAYMENT = 'PAYMENT',
  PAYMENT_PRESET = 'PAYMENT_PRESET',
  REGISTRATION = 'REGISTRATION',
  GET_SUBSCRIBERS = 'GET_SUBSCRIBERS',
}
